<template>
  <div>
    <PageHeader :header="headData" />
    <v-container
      :class="[item.color, item.textColor]"
      fluid
      v-for="(item, i) in section"
      :key="i"
    >
      <v-row tag="section" justify="center" class="grey lighten-5">
        <v-col cols="12">
          <h2 class="text-h4 text-md-h3 font-weight-bold my-16 text-center">
            {{ item.title }}
          </h2>
        </v-col>
        <v-col cols="12" sm="7" lg="6">
          <div class="mb-16 white rounded-xl pa-6" v-html="item.text"></div>
          <div class="text-right my-12">
            <p><strong>松延康隆</strong></p>
            <p>(現代の理論 2017 夏号 「歴史遊学」④より)</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: { PageHeader },
  name: "History",

  data: () => ({
    dialog: false,
    headData: {
      title: "福島の歴史",
      img: "/assets/pic/header/about-header_mini.jpg"
    },
    section: [
      {
        title: "天明の大飢饉と相馬中村藩",
        text: `<h2 class="mb-2">1.はじめに</h2>
        <p>福島県南相馬市は、2011(平成23)年の東日本大震災による津波により、636人の死者・不明者を出すという甚大な被害を蒙った。さらに、続いて起こった東電福島第一原発事故による放射能汚染により、市の南半を占める小高区全域と原町区の一部が避難指示区域とされた。2016(平成28)年7月に避難指示区域の指定は解除されたが、2017(平成29)年4月現在で、小高区の帰還者は震災以前の20％弱であり、さらに帰還者の大半がご高齢者であるという状況である。</p>
        <p>筆者は2017年4月、小高区を訪れ市関係者から現状を聞く機会を得たが、今後広大な地域に散在するご高齢者の医療・介護等を巡って、さらに深刻な状況が生じることを危惧する声が圧倒的であった。</p>
        <p>ところで、この地域は江戸時代には6万石の大名である相馬中村藩の領国であったが、18世紀末に起こった天明の大飢饉により今回の震災に匹敵するほどの大災害に見まわれている。7年に及ぶ全国的な悪天候や冷害、さらに浅間山の大噴火によって激化したこの飢饉は東北地方で特に激しく、陸奥国だけで数十万人の餓死者が出たとされる。相馬中村藩領でも、死者・離村者による人口の半減、耕作放棄地の激増、藩債の累増といった事態が生じた。
この危機的状況下で相馬中村藩は19世紀初頭から徹底した藩政改革を断行し、1871(明治4)年の廃藩置県の際には人口の増加・耕地の拡大、藩財政の再建といった点で完全な復興を成し遂げていた。この相馬中村藩の復興を可能とした政策を検討することによって、現在の東日本大震災被災地の復興の問題点を考えてみたい。
</p>
<h2 class="mb-2 mt-10">2.奥州相馬氏の由来</h2>
        <p>相馬氏は、源頼朝の幕府創業を支えた大御家人・千葉常胤の二男の師常が、“相馬師常”と称したことに始まる。師常は下総の相馬御厨（現・千葉県流山市付近）を本拠地としたが、1189(文治5)年、源頼朝による藤原泰衡追討に加わり、その軍功により陸奥国行方郡(現・南相馬市)の地頭に補任された。以来、1871(明治4)年の廃藩置県まで約700年間にわたって、この地域は相馬氏の支配下に置かれた。このような、同一領主による長期間にわたる支配は世界史的にも珍しく、日本では他に薩摩の島津氏、肥後の相良氏が知られるのみである。</p>
        <p>ところで、現在も南相馬市で毎年7月に盛大に開催される「相馬野馬追」は、相馬氏(千葉氏)の遠祖で、10世紀に東国で大反乱を起こし、短期間ではあるが東国独立国家を樹立した平将門の軍事訓練に由来すると伝承されている。</p>
        <p>鎌倉時代末期の1323年(元亨3)、相馬師常の子孫・相馬重胤は一族郎党を率いて相馬御厨から行方郡小高郷へ移住し、奥州相馬氏の祖となった。南北朝の内乱期には奥州相馬氏は北朝・足利尊氏方で戦ったが、一方千葉氏、下総相馬氏は南朝方であり、本家と対立する結果となった。千葉氏、下総相馬氏は戦国時代に衰退・滅亡の途を辿ったが、奥州相馬氏は強固な領国支配を誇り、隣接する伊達氏と死闘を繰り返したことで知られる。</p>
        <p>1590(天正18)年、奥州相馬氏は全国を統一した豊臣秀吉に臣従して、行方・宇多・標葉の三郡48,000石を安堵された。その後、1600(慶長5)年の関ヶ原の戦いでは徳川家康の要請を無視して出兵せず、そのため1602(慶長7)年に、家康によって領土を没収されたが、この処置は直後に撤回された。改易直後の撤回という先例のない決定がなぜなされたのか、その理由は明らかではない。</p>
        <p>ただ、過去数百年にわたる奥州相馬氏の領国支配によって結ばれた、領主と領民との強固な紐帯が改易撤回の背景にあることは確実であろう。</p>
        <h2 class="mb-2 mt-10">3.相馬中村藩の藩政</h2>
        <p>1611(慶長16)年、初代藩主・相馬利胤は居城を小高城から中村城へ移し、ここに6万石の近世大名としての相馬中村藩が成立した。相馬中村藩は初期から財政窮乏に悩まされた。幕藩領主の財政窮乏の根本的要因は、米を中心とする現物公租を換金して江戸と国元で消費生活を送るという経済構造そのものにある。その上に相馬中村藩の特殊な事情として次の2点が加わった。</p>
        <p>まず、奥州相馬氏は鎌倉時代以来継承されてきたことから、他の近世大名に比して家臣数が多く、家臣に支給する俸禄米が多かったこと。さらに、表高6万石であるにも関わらず、新田開発による増加分があったとして、幕府に10万石の家格と届け出たこと。</p>
        <p>当然ながら、将軍の日光社参に伴う分担金や普請役などの幕府が課す公役は各大名の知行高に応じて付加されるため、その負担が大きかった。また相馬中村藩領の出来高も17世紀末をピークとして漸減したことにより、18世紀中期には財政危機が深刻化していた。</p>
        <h2 class="mb-2 mt-10">4.天明の大飢饉</h2>
        <p>1782(天明2)年に始まる全国的な凶作は、翌年の浅間山大噴火によって激化し、翌1784(天明4)年をピークとして1787年(天明7)年に及んだ。1784(天明4)年の相馬中村藩の損耗高は76,000石に達し、1783年から翌1784年にかけて、死者4,417人・離散者1,831人という記録が残されている。領民数は1778（安永8）年から1787（天明7）年の10年間で、57,794人から32,248人に減少した。</p>
        <p>1784年(天明4)には第9代藩主・相馬祥胤が幕府から5,000両の貸与を受けて、手持ち金を加えて7,690両で他領より食料を購入して領民に無料配布するなどの対策をとっている。しかし、うち続く凶作のなかで餓死者・離散者は増加の一途をたどり、これと対応して藩の負債も激増し、1804(文化1)年には20万両を越えた。これは相馬中村藩の全歳入を当てても利息に達しない負債額であった。</p>
        <h2 class="mb-2 mt-10">5.相馬中村藩の財政改革</h2>
        <p>1817(文化14)年、第11代藩主・相馬益胤は、城下の士分を総登城させて徹底的な議論をさせた上で、「文化の御厳法」と呼ばれる藩政刷新政策を決定した。その中心は、相馬中村藩は以後5年間にわたり6万石の格式を1万石に切り下げ、1万石に見合った緊縮予算の確立・執行をはかるという財政再建策であった。益胤はこの緊縮財政策を5年間厳格に実行し、1822(文政5)年に、さらに6年間延長した。</p>
        <p>この結果、藩財政は再建されたが、1833(天保4)年から1839(天保10)年にかけて、洪水・冷害による連年の凶作が続いた。この天保の大飢饉は東北地方を中心に大きな被害をもたらしたが、相馬中村藩領では御厳法による貯穀により一人の餓死者も出さなかったといわれる。</p>
        <p>しかし、藩財政は再び危機におちいり、益胤は御厳法をさらに10年間延長した。相馬中村藩の財政再建は、藩主を含む全藩士の生活を極限まで切り詰めること、その長期間にわたる継続によって初めて可能であった。</p>
        <h2 class="mb-2 mt-10">6.相馬中村藩の荒村復興策</h2>
        <p>相馬中村藩の藩政改革の最大の課題は、天明・天保の大飢饉により荒廃した農村の復興であり、このためには農業人口の増加による荒蕪地の開墾が必要であった。</p>
        <p>まず人口の増加策として採用されたのが、他藩からの移民を積極的に招致することであった。幕藩体制下では他藩からの移民の受け入れは原則として禁止されていたが、1810(文化7)年より加賀・越中・越後・能登などの浄土真宗門徒が大量に相馬中村藩領に移住したことが確認できる。来住移民総戸数は1845(弘化2)年までに1,800戸に達しており、相馬中村藩と東西本願寺との間に、真宗門徒の移住に関する何らかの密約があったものと推定されている。</p>
        <p>ところで、当時の真宗門徒は一種の仏教原理主義集団であり、強固な信仰で結ばれ、勤勉であるとともに間引きによる人口抑制を教義上禁じているといった点で、移民には最適の集団であった。しかし、他藩から秘密裏にこれだけ大量の移民を招致するにあたっては、住居・食料・耕作地の提供や一定期間の租税の免除等、周到な準備が必要であったことは言うまでも無い。</p>
        <p>さらに、従来の領民と信仰・習俗・文化などの異なる集団を招くことは、通常新旧領民の対立という事態を生じる恐れが高い。これらの点を勘案するとき、大量の移民を受け入れて生活を安定させ、従来の農民との摩擦を生じさせることなく、同じ領民として共存させることに成功した相馬中村藩の民政の手腕は極めて高度であるといわざるを得ない。</p>
        <p>さらに、荒村復興策として第12代藩主・相馬充胤によって御仕法(報徳仕法)が採用された。御仕法とは、江戸時代後期の農政家・思想家の二宮尊徳が提唱した農村復興策であり、その根本をなすのは分度と推譲の実践である。分度とは村の過去の納入公租の平均値から割り出した生活の限度であり、推譲とは分度を超えた余剰を他者に譲ることで、この推譲分が相互扶助や弱者救済、公共資本の充実などに当てられることによって、農村全体の復興がはかられることになる。御仕法は村を単位として実施されるが、生活の限度額が定められ、それを越える余剰分を他者に譲るなどということは、村民にとって到底簡単に納得できることではない。</p>
        <p>事実、御仕法は藩主の命によって決定されたのではなく、藩内の村々において全村民参加の寄合における長期間の討論の上でその採用の適否が決定された。実施にあたっては、まず分度の算定が大問題であり、相馬中村藩では二宮尊徳が自ら分度の算定に当たった。このとき相馬中村藩は、過去180余年間の各村の納入公租に関する文書を提出して、尊徳を驚嘆させたといわれる。</p>
        <p>御仕法は、1845(弘化2)年から1871(明治4)年の27年間、相馬中村藩領内226ヶ村のうち101ヶ村で実施され、55ヶ村では完了し、46ヶ村は廃藩置県にともない中止された。相馬市史には御仕法成績として数十項目にわたってその成果が記されているが、紙数の関係から、戸数増加1,135戸・人口増加21,715人、荒蕪地開墾1,379町歩・増収102,872俵という数字のみを上げておく。相馬中村藩の復興が完全に達成されたことは明らかであろう。</p>
        <h2 class="mb-2 mt-10">7.おわりに</h2>
        <p>1871(明治4)年の廃藩置県に際して、相馬中村藩は46ヶ村でなされていた御仕法の継続を明治新政府に強く求めたが、地租改正を準備中であった新政府によって無視され、御仕法は中断を余儀なくされた。地租改正という近代的土地私有権を前提とする税制の下で、年貢・公役の村請制を前提とし、村を単位に実施される御仕法の継続は不可能であった。このことは、幕藩領主制国家と近代国民国家の根本的相違を示している。</p>
        <p>私達は長らく近代国民国家の成立を、進歩とか開化とする歴史観になじんできた。しかし、相馬中村藩の事例だけでも、苛斂誅求を旨とする領主による過酷な支配とか、閉鎖的村落共同体に支えられた封建社会といった通念は払拭されよう。</p>
        <p>私達の「議会制民主主義」は、相馬中村藩の“文化の御厳法”や“御仕法”を決定するに当たっての対話と合意形成の水準に達しているのか？現代日本の政治家・官僚は、責任感や倫理観において相馬中村藩の為政者に比して恥ずべきではないのか？</p>
        <p>私達は今、真剣に歴史を問い返さなくてはならない。</p>
        <p>復興の最高責任者が被災者に対して「自己責任！」と暴言を吐く国。過去180余年間の納税文書を保管・提供した相馬中村藩に比して、わずか2年前の国有地払い下げに関する文書が紛失する(在るのに公表できない？）国。私達はこんな国の国民なのだから。</p>
        `,
        color: "grey lighten-5",
        textColor: "text--secondary"
      }
    ],
    groupOverview: {
      name: "NPO法人 こども達に未来を in 湘南",
      establish: "2012年10月",
      address: "〒251-0032 神奈川県藤沢市片瀬4-1-16",
      phone: "0466-55-225",
      fax: "0466-22-8721",
      mail: "kodomotachimirai@yahoo.co.jp",
      rep: "松延康隆"
    }
  })
};
</script>
<style lang="scss" scoped>
.report-box {
  dd {
    padding-bottom: 8px;
  }
}
</style>

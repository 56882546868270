<template>
  <div class="grey lighten-5">
    <PageHeader :header="headData" />
    <v-container>
      <v-row tag="section" justify="center">
        <v-col cols="12" sm="8" class="my-16 white rounded-xl pa-6">
          <v-list-item two-line v-for="(link, k) in links" :key="k">
            <v-list-item-content>
              <v-list-item-subtitle
                v-html="link.category"
              ></v-list-item-subtitle>
              <v-list-item-title
                v-html="link.text"
                class="links-list"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: { PageHeader },
  name: "Links",

  data: () => ({
    headData: {
      title: "リンク集",
      img: "/assets/pic/header/about-header_mini.jpg"
    },
    links: [
      {
        category: "公式Webサイト",
        text: `<p><a href="http://ccfj.la.coocan.jp/">チェルノブイリの子どもたち</a></p>`
      },
      {
        category: "Youtube",
        text: `<p><a href="https://www.youtube.com/watch?v=FGoLzIbEOTU">チェルノブイリ35年・福島10年救援イベント豊田直巳講演会「チェルノブイリから福島」</a></p>`
      },
      {
        category: "Facebook",
        text: `<p><a href="https://m.facebook.com/%E3%83%81%E3%83%BC%E3%83%A0%E3%81%BF%E3%81%A4%E3%81%B0%E3%81%A1-109571621171734/">チームみつばち</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://catholicfujisawa.org/">カトリック藤沢教会</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="http://www.myouzenji.com/">宗教法人 妙善寺</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://www.ayadomenig.ch/?fbclid=IwAR2WzGsNrSts0DmGpraqfrzFmQAXumJF3OJ10KBHw-f7fYHkvuD7BbXDLvE">ドキュメンタリー映画「SILENT FUKUSHIMA(チンモクノフクシマ)」(アヤ=ドメーニグ監督)</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://toyoda-fukushima-photo.mystrikingly.com/">「豊田直巳写真展『フクシマの７年間〜尊厳の記録と記憶』全国巡回プロジェクト」</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="http://yuigon-fukushima.com">映画『サマショール 遺言 第六章』&『遺言 原発さえなければ』</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://ubawaretamura.mystrikingly.com/">映画『奪われた村 避難5年目の飯舘村民』</a></p>`
      },
      {
        category: "Youtube",
        text: `<p><a href="https://ubawaretamura.mystrikingly.com/">映画『遺言』(予告編)</a></p>`
      },
      {
        category: "Youtube",
        text: `<p><a href="https://www.youtube.com/watch?v=vVLNzpfL5Ms&t=18s">映画『サマショール』(予告編)</a></p>`
      },
      {
        category: "Twitter",
        text: `<p><a href="https://twitter.com/NaomiTOYODA7">豊田直巳 映画『サマショール 〜遺言 第六章』</a></p>`
      },
      {
        category: "Youtube",
        text: `<p><a href="https://www.youtube.com/watch?v=RYDgmeLeHmQ&t=2s">映画『奪われた村』(予告編)</a></p>`
      },
      {
        category: "キャンペーンサイト",
        text: `<p><a href="https://www.change.org/p/%E4%B8%AD%E5%9B%BD%E9%9B%BB%E5%8A%9B-%E4%B8%8A%E9%96%A2%E5%8E%9F%E7%99%BA%E8%A8%88%E7%94%BB%E3%81%AF%E3%81%84%E3%82%89%E3%81%AA%E3%81%84-%E7%AC%AC6%E6%AC%A1%E3%82%A8%E3%83%8D%E3%83%AB%E3%82%AE%E3%83%BC%E5%9F%BA%E6%9C%AC%E8%A8%88%E7%94%BB%E3%81%AB%E5%8E%9F%E7%99%BA%E3%81%AE%E6%96%B0%E5%A2%97%E8%A8%AD%E3%82%92%E6%98%8E%E8%A8%98%E3%81%95%E3%81%9B%E3%81%AA%E3%81%84%E3%81%9F%E3%82%81%E3%81%AB?recruiter=519075176&utm_source=share_petition&utm_medium=twitter&utm_campaign=share_petition&recruited_by_id=ae8b0d60-f724-11e5-bdba-9b4abedb4252">中国電力・上関原発の新設計画反対キャンペーン</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://someshi-ueta.stores.jp/">奄美の染色師・植田正輝</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://www.taisetsujikan.com/">パルシステム神奈川 「たいせつじかん」</a></p>`
      },
      {
        category: "公式Webサイト",
        text: `<p><a href="https://shounanonlinenetworkservice.com/">Shounan On-line Network Service</a></p>`
      },
      {
        category: "Youtube",
        text: `<p><a href="https://www.youtube.com/user/Masafumitanaka/featured">Youtubeチャンネル</a></p>`
      }
    ]
  })
};
</script>
<style lang="scss" scoped>
.links-list {
  white-space: normal;
}
</style>
